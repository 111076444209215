<template>
  <div class="Memberlistpar">
    <div class="top-titles">
      <div class="title"><span>会员列表</span>/会员详情</div>

      <div class="top-box">
        <div class="top-head">
          <div class="top-head-img">
            <img v-if="listpar.profile_photo !== ''" :src="listpar.profile_photo" alt="" />
            <img v-if="listpar.profile_photo == ''" src="../../assets/image/touxiang.png" alt="" />
          </div>
          <span>{{ listpar.true_name }}</span>
        </div>
        <div class="top-money">
          <span>余额：</span>
          <span>{{ listpar.balance }}元</span>
        </div>
      </div>
    </div>

    <!-- 详情-->
    <div class="particulars">
      <div class="particulars-box">
        <div class="particulars-list">
          <span class="pl-tltle">姓名:</span><span class="pl-content">{{ listpar.true_name }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">昵称:</span><span class="pl-content">{{ listpar.nickname }}</span>
        </div>

        <div class="particulars-list">
          <span class="pl-tltle">上级推荐人:</span><span class="pl-content">{{ listpar.inviter_name }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">推荐人手机号:</span><span class="pl-content">{{ listpar.inviter_mobile }}</span>
        </div>


        <div class="particulars-list">
          <span class="pl-tltle">身份证号:</span><span class="pl-content">{{ listpar.idcard }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">民族:</span><span class="pl-content">{{ listpar.nation_name }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">籍贯:</span><span class="pl-content">{{ listpar.birthaddr }}</span>
        </div>


        <div class="particulars-list">
          <span class="pl-tltle">工种:</span><span class="pl-content">{{ listpar.work_type }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">会员级别:</span><span class="pl-content">{{ listpar.memberLevelName }}</span>
        <el-button  type="warning"  class="biy-icon" @click="chakan()">查看粉丝</el-button>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">邀请码:</span><span class="pl-content">{{ listpar.self_inviteCode }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">手机号:</span><span class="pl-content">{{ listpar.mobile }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">性别:</span><span class="pl-content">{{ listpar.gender }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">注册时间:</span><span class="pl-content">{{ listpar.regtime }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">余额:</span><span class="pl-content">{{ listpar.balance }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">技能:</span><span class="pl-content">{{ listpar.work_skills }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">工作经历:</span><span class="pl-content">{{ listpar.work_history }}</span>
        </div>
        <div class="particulars-list">
          <span class="pl-tltle">自我评价:</span><span class="pl-content">{{ listpar.self_evaluation }}</span>
        </div>

        <div class="particulars-list">
          <span class="pl-tltle">停用状态:</span><span class="pl-content">{{ listpar.stoped }}</span>
        </div>
      </div>
            <div class="particulars-img">
        
               <div class="particulars-list" >
               <span class="pl-tltle">身份证人像面:</span>
               <viewer :images="listpar"> 
               <img :src="listpar.idcard_npic" alt="">
               </viewer>
               </div>
             <div class="particulars-list" >
               <span class="pl-tltle">身份证国徽面:</span>
               <viewer :images="listpar"> 
               <img :src="listpar.idcard_ppic" alt="">
               </viewer>
              </div>

      </div>
        <div class="particulars-illegal">
              <ul>
                <li>违规类型</li>
                <li>上班迟到</li>
                <li>未上班</li>
                <li>用工方或用户自己中断</li>
                <li>线下恶意交易</li>
              </ul>
              <ul>
                <li>违规类型解释</li>
                <li>上班未到岗一次警告提示，2次停用一周，（上班时间超过30分钟没点击申请开工，后台给该用户警告提示“您未在规定时间上岗，给予警告一次，累计两次停用一周”</li>
                <li>需线下恶意交易一次停用一周，达到两次列入黑名单</li>
                <li>对于自己结束工作或被用工方中途中断，超过3次停用一周，（非正常结束，给予警告提示前两次都是警告）会员报名了，但是没有来上班，通过打电话确认是线下交易</li>
                 <li>需线下恶意交易一次停用一周，达到两次列入黑名单 会员报名了，但是没有来上班，通过打电话确认是线下交易</li>
               
              </ul>
              <ul>
                <li>违规次数</li>
                <li>{{listpar.breakTotal0}} <el-button  type="primary"
                class="biy-icon" @click="zhengjia('0')" >增加一次</el-button></li>
                <li>{{listpar.breakTotal1}}  <el-button type="primary"
                class="biy-icon" @click="zhengjia('1')">增加一次</el-button></li>
                <li>{{listpar.breakTotal2}}   <el-button type="primary"  
                class="biy-icon" @click="zhengjia('2')" >增加一次</el-button></li>
                <li>{{listpar.breakTotal3}}   <el-button type="primary"  
                class="biy-icon" @click="zhengjia('3')" >增加一次</el-button></li>
              </ul>
        </div>
       <div class="particulars-affirm">
        <el-form ref="form" label-width="100px">
          <div class="">
          <el-form-item label="请选择原因：" >
               <el-radio-group v-model="radio">
                 <el-radio v-for="(r , i) in radiopar.one" :key="i" :label="r.value">{{r.lable}}</el-radio>
               </el-radio-group>
          </el-form-item>
            </div>
          <div class="">
          <el-form-item label="请选择日期：" >
            <el-date-picker
              v-model="radiopar.time" type="date"
              placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
            </div>
          <div class="">
          <el-form-item label="处罚：" >
            <el-select v-model="listpar.stophanging" placeholder="违规处罚">
              <el-option  v-for="(r, i) in seeklist.four" :key="i" :label="r.label" :value="r.value"></el-option>
            </el-select>
          </el-form-item>
            </div>

          <div class="" style="text-align: center">
           <el-button
                type="primary"
                class="biy-icon" @click="seek()"
                >处罚</el-button>
            </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js";
export default {
  data() {
    return {
      radio:0,
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      listpar: {},
      breakTotal:'',
            // 下拉框
      seeks: {},
      seeklist:fakelist.memberlist,

       etype:null,
   
       radiopar:{
         one:[
           {lable:'不发短信',
           value:0,},
           {lable:'线下恶意交易',
           value:1,},
           {lable:'用工方或用户自己中断',
           value:2,},
         ],
         time:''
       }
    };
  },

  created() {
    this.getSong();
  },
  mounted() {},

  methods: {
        //弹窗
      zhengjia(e){
          this.etype = e
          this.$confirm('是否确认增加一条记录', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.zhengjias()
          }).catch(action => {
            console.log("取消");
          })
      },

   async zhengjias(){
     let res = await apiurl.entityDataHandle({
        FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
        loginName:this.$common.baseGetuSername(),//登录账号 
        loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'WorkerEntity',
        handleMode:'breakRecord',
        workerId:this.$route.params.id,
        breakType:this.etype,
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
        this.getSong()
      },

    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
        FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
        loginName:this.$common.baseGetuSername(),//登录账号 
        loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'fetch',
        bizCatalog:'WorkerEntity',
        workerId:this.$route.params.id,
        })
        console.log(res);
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.listpar = res.Result;
      },


// 设置状态接口
     async seek(){  
       let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家

        bizCatalog:'WorkerEntity',
        handleMode:'stop',
        workerId:this.listpar.id,
        todo:this.listpar.stophanging,
        breakType:this.radio,
        date:this.radiopar.time
       })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.$alert('设置成功', '消息提示', {confirmButtonText: '确定'});
         this.getSong()
      },

    // 查看粉丝
      chakan(){
         this.$router.push({ path: `/home/directlymenber/${this.listpar.workerId}` });
      }

  },
  computed: {},
};
</script>


<style  scoped>

@import "../../assets/css/parstyle.css";
</style>